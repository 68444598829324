/* ======================================================================= Colors ======================================================================= */
:root {
    --background: #171a21;
    --sidebar: #1b2838;
    --header: #1b2838;
    --scrollbar: #2a475e;
    --text: #c7d5e0; ;
}

/* ======================================================================= Base Styling ======================================================================= */
body {
    margin: 0%;
    padding: 0%;
    height: 100%;
}
html {
    background-color: var(--background);
    color: var(--text);
    font-family: 'Courier New', Courier, monospace;
    margin: 0%;
    padding: 0%;
    min-width: 434px;
    height:100%;
}
.Page {
    height: 100vh;
}
marquee {
    background-color: var(--sidebar);
}
h2 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0rem;
    margin-left: 0.75rem;
    min-width: 110px;
}
::-webkit-scrollbar {
    width: 15px;
    background-color: var(--background)
}
::-webkit-scrollbar-track {
    background: var(--background);
}
::-webkit-scrollbar-thumb {
    background: var(--scrollbar);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--sidebar);
}
@media only screen and (max-width: 769px) {
    ::-webkit-scrollbar {
        width: 8px;
        color: var(--sidebar);
    }
    ::-webkit-scrollbar-track {
        background: var(--sidebar);
    }
    ::-webkit-scrollbar-thumb {
        background: var(--sidebar);
    }
}


/* ======================================================================= Sidebar and Components Styling ======================================================================= */
/* --------------- Sidebar styling --------------- */
.Sidebar {
    float: left;
    background-color: var(--sidebar);
    min-height: 100%;
    width: 435px;
}
/* --------------- Sidebar Cover / Title styling / Video --------------- */
.SidebarCover {
    border-radius: 2rem;
    margin: 1rem;
    margin-bottom: 0rem;
}
.Scroller {
    font-size: x-large;
    position: relative;
    left: 4px;
    top:12px;
    border: solid 2px;
    border-top: 0px;
    border-bottom:0px;
    font-weight: bold;
}

.YoutubeEmbed {
    margin: 1rem;
    margin-top: 1.5rem;
}
/* --------------- Sidebbar Button styling --------------- */
.SidebarButton {
    float:left;
    background-color: var(--sidebar);
    margin: 0.25rem;
    margin-left: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
}
.SidebarButton:hover {
    transform: scale(1.1, 1.1);
}
.SidebarButton:active {
    transform: scale(1.05, 1.05);
}
.InfoButton {
    float: right;
    background-color: var(--sidebar);
    margin: 0.25rem;
    margin-right: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
}
.InfoButton:hover {
    transform: scale(1.1, 1.1);
}
.InfoButton:active {
    transform: scale(1.05, 1.05);
}
/* ======================================================================= Header and Components Styling ======================================================================= */
/* --------------- Header styling --------------- */
.Header {
    background-color: var(--header);
    height:55px;
    display: block;
}

@media only screen and (max-width: 769px) {
    .Header{visibility: hidden;}
}
/* --------------- Icon Button / Setting Button styling --------------- */
.IconButton {
    background-color: var(--header);
    margin: 0.25rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
}
.IconButton:hover {
    transform: scale(1.1, 1.1);
}
.IconButton:active {
    transform: scale(1.05, 1.05);
}
.SettingIconButton {
    float: right;
    background-color: var(--header);
    margin: 0.25rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
    visibility: hidden;
}
/* --------------- Search Input styling --------------- */
.SearchBar {
    bottom: 7.5px;
    height: 2.5rem;
    margin-left: 0.4rem;
    position: relative;
    outline: none;
    background-color: var(--header);
    color: var(--text);
    border: 0rem;
    border-radius: 0.75rem;
    font-size: x-large;
    margin-bottom: 1rem;
    font-family: 'Courier New', Courier, monospace;
}
/* ======================================================================= Library Row Styling ======================================================================= */
.RowTitle {
    display: inline;
    position: relative;
    bottom: 29px;
}
.RowLike {
    background-color: var(--background);
    margin: 0.25rem;
    margin-left: 0.75rem;
    margin-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    position: relative;
    bottom: 18px;
}
.RowLike:hover {
    transform: scale(1.075, 1.075);
}
.RowLike:active {
    transform: scale(1.05, 1.05);
}
.RowButton {
    margin-top: 0.3rem;
    padding: 0rem;
    border: 0rem;
    background-color: var(--background);
}
.RowImg {
    border-radius: 0.75rem;
}
.RowImg:hover {
    transform: scale(1.075, 1.075);
}
.RowImg:active {
    transform: scale(1.05, 1.05);
}
.Library {
    list-style-type: none;
    margin-top: 0rem;
    overflow: auto;
    min-height: 840px;
    height: 92vh;
    flex-grow: 1;
}
li {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.ListMain {
    display: flex;
}
/* ======================================================================= Grid Styling ======================================================================= */
.GridButton {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    padding: 0rem;
    border: 0rem;
    background-color: var(--background);
}
.GridImg {
    border-radius: 0.5rem;
}
.GridImg:hover {
    transform: scale(1.075, 1.075);
}
.GridImg:active {
    transform: scale(1.05, 1.05);
}
@media only screen and (max-width: 767px) {
    .GridImg{transform: scale(0.9, 0.9);
    margin: -7px;}
}
.Liked {
    list-style-type: none;
    margin-top: 0rem;
    overflow: auto;
    min-height: 840px;
    height: 92vh;
    flex-grow: 1;
}
.copyright {
    margin: 1rem;
    text-align: center;
    font-size: small;
    color: var(--scrollbar);
}
.LikedIconButton {
    background-color: var(--background);
    margin: 0.25rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
    float: left;
}
.LikedIconButton:hover {
    transform: scale(1.1, 1.1);
}
.LikedIconButton:active {
    transform: scale(1.05, 1.05);
}
.MobileRandomButton {
    float:left;
    background-color: var(--sidebar);
    margin: 0.25rem;
    margin-left: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    border: 0rem;
    border-radius: 0.75rem;
}
.ComposerList {
    margin-left: 3.6rem;
    font-style: italic;
    list-style-type: none;
}
.Composers {
    margin-left: 3.6rem;
    font-weight: bold;
    font-size: large;
    margin-bottom: 0.3rem;
}
.ReleaseDate {
    margin-top: 0.4rem;
    margin-left: 3.6rem;
}
.Released {
    margin-top: 2rem;
    margin-left: 3.6rem;
    font-weight: bold;
    font-size: large;
    margin-bottom: 0.3rem;
}
.InfoBox {
    background-color: var(--scrollbar);
}
.ExpandYoutube {
    margin: 10px;
    width: 150px;
    margin-left: 8.8rem;
    margin-bottom: 2px;
    background-color: var(--sidebar);
    color: var(--text);
    border: 0rem;
    border-radius: 0.75rem;
}
.RetrackYoutube {
    margin: 10px;
    width: 150px;
    margin-left: 11.1rem;
    margin-bottom: 2px;
    background-color: var(--sidebar);
    color: var(--text);
    border: 0rem;
    border-radius: 0.75rem;
}
@media only screen and (max-width: 767px) {
    .ExpandYoutube{visibility: hidden;}
    .RetrackYoutube{visibility: hidden;}
}